<template>
  <div class="email-code-verify">
    <div class="email-code-verify__description">
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.emailVerificationDescription') }}
    </div>

    <form-input-code
      ref="codeInput"
      v-model:completeCode="completeCode"
      :hint="props.hint"
    />

    <div class="email-code-verify__resend">
      <div v-if="timer" class="email-code-verify__resend-timer">
          <div class="resend">
            {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.timerLabel') }}
          </div>

          <div class="time">
            <span>{{ Math.floor(currentTime / 60) }}</span>:<span>{{ String(currentTime % 60).padStart(2, '0') }}</span>
          </div>
        </div>

        <div v-else class="resend" @click="resendCode">
          <span>{{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.resendText') }}</span>
          <span class="link">{{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.resendLink') }}</span>
        </div>
    </div>

    <div class="email-code-verify__actions">
      <button-base
        class="email-code-verify__cancel-button"
        tagName="div"
        type="gray"
        size="md"
        @click="emit('cancel')"
      >
        {{ props.buttonCancelLabel }}
      </button-base>

      <button-base
        class="email-code-verify__verify-button"
        tagName="div"
        type="primary"
        size="md"
        :isDisabled="!completeCode || props.hint || props.loading || limitError"
        @click="verifyCode"
      >
        <atomic-spinner :is-shown="props.loading"/>
        {{ props.buttonSubmitLabel }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IFieldHint } from '~/types';

  const layoutStore = useLayoutStore();
  const { closeModal, showAlert } = layoutStore;

  const props = defineProps<{
    hint?: IFieldHint;
    loading: boolean;
    buttonSubmitLabel: string;
    buttonCancelLabel: string;
  }>();

  const emit = defineEmits(['verifyCode', 'removeHint', 'cancel', 'resendCode']);
  const {
    alertsData,
    defaultLocaleAlertsData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
  } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const completeCode = ref<string>('');

  watch(completeCode, () => {
    if (props.hint) emit('removeHint');
  });

  const TIMER_TIME = 60;
  const timer = ref<any>(undefined);
  const currentTime = ref<number>(TIMER_TIME);

  const startTimer = ():void => {
    timer.value = setInterval(() => {
      if (currentTime.value === 0) {
        clearInterval(timer.value);
        timer.value = undefined;
        return;
      }
      currentTime.value -= 1;
    }, 1000);
  };

  const resendingCode = ref<boolean>(false);
  const limitError = ref<boolean>(false);
  const resendCode = async (): Promise<void> => {
    if (resendingCode.value) return;

    resendingCode.value = true;

    try {
      emit('resendCode');
      currentTime.value = TIMER_TIME;
      startTimer();
    } catch (error: any) {

      if (error.status === 429) {
        showAlert(alertsData?.value?.profile?.tooManyAttempts || defaultLocaleAlertsData?.value?.profile?.tooManyAttempts);
      } else {
        showAlert({
          title: error.response._data.error.message,
          type: 'error'
        });
      }
    }

    resendingCode.value = false;
  }

  const verifyCode = async(): Promise<void> => {
    if (props.loading) return;
    emit('verifyCode', completeCode.value);
  }

  const codeInput = ref();

  onMounted(() => {
    startTimer();
  });

  onBeforeUnmount(() => {
    clearInterval(timer.value);
  });
</script>

<style src="~/assets/styles/components/form/email-code-verify.scss" lang="scss" />
